import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "Aventura-logo.png" }) {
        id
        childImageSharp {
          fixed(width: 700, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <div>
      <Img fixed={data.image.childImageSharp.fixed} />
    </div>
  )
}

export default Logo
